// Move Type
export const PUSH = "PUSH"
export const UNDO = "UNDO"
export const REDO = "REDO"
export const CLEAR = "CLEAR"

// Student Flag Type
export const STUDENT_NEW_GRADE_CHANGE = "STUDENT_NEW_GRADE_CHANGE"
export const STUDENT_UPDATED = "STUDENT_UPDATED"
export const DELETED_STUDENT = "DELETED_STUDENT"

// Settings
export const ADMIN_ONLY = "ADMIN_ONLY"

// Settings
export const UPLOAD_JOB_ID = "UPLOAD_JOB_ID"
