import React from "react"
import { Query } from "@apollo/client/react/components"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  surveyStudentsQuery,
  createOrUpdateStudentMutation,
} from "domains/students/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"
import { closeOrOpenTeacherSurveyMutation } from "domains/teachers/graphql"
import { preferencesQuery, schoolQuery } from "domains/accountSettings/graphql"

import { Loader, QueryError } from "components"
import { SurveyStudents } from "domains/surveyStudents/components"

import { SCHOOL_ID } from "constants/storageTokens"
import { getSchoolId } from "util/app"
import { AccountTypeProvider } from "config/accountTypeContext"

class SurveyStudentsPageComponent extends React.Component {
  render() {
    const {
      teacherId,
      client,
      schoolCharacteristics,
      schoolSettings,
      loadingOne,
      loadingTwo,
      createOrUpdateStudentMutation,
      closeOrOpenTeacherSurveyMutation,
      school,
      loadingSchool,
    } = this.props
    const adminOnlyRequests =
      schoolSettings === undefined ? false : schoolSettings.adminOnlyRequests

    return (
      // Students Query for Teachers Survey
      <Query
        query={surveyStudentsQuery}
        variables={{
          teacherId: teacherId,
          adminOnly: adminOnlyRequests,
        }}>
        {({ loading, error, data, refetch }) => {
          if (loading || loadingOne || loadingTwo || loadingSchool) {
            return <Loader />
          }

          if (error) return <QueryError error={error} refetch={refetch} />

          const { surveyStudents } = data

          const refetchQueries = [
            {
              query: surveyStudentsQuery,
              variables: { teacherId: teacherId, adminOnly: adminOnlyRequests },
            },
          ]

          return (
            <AccountTypeProvider school={school}>
              <SurveyStudents
                teacherId={teacherId}
                client={client}
                surveyStudents={surveyStudents || []}
                schoolSettings={schoolSettings}
                schoolCharacteristics={schoolCharacteristics}
                createOrUpdateStudentMutation={createOrUpdateStudentMutation}
                closeOrOpenTeacherSurveyMutation={
                  closeOrOpenTeacherSurveyMutation
                }
                refetchQueries={refetchQueries}
              />
            </AccountTypeProvider>
          )
        }}
      </Query>
    )
  }
}

export const SurveyStudentsPage = compose(
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loadingOne: loading,
      schoolCharacteristics,
    }),
  }),
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
    }),
    props: ({ data: { loading, schoolSettings } }) => ({
      loadingTwo: loading,
      schoolSettings,
    }),
  }),
  graphql(schoolQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { loading, school } }) => ({
      loadingSchool: loading,
      school: school,
    }),
  }),
  graphql(createOrUpdateStudentMutation, {
    name: "createOrUpdateStudentMutation",
  }),
  graphql(closeOrOpenTeacherSurveyMutation, {
    name: "closeOrOpenTeacherSurveyMutation",
  })
)(withApollo(SurveyStudentsPageComponent))
