import React from "react"
import { Navigate } from "react-router-dom"
import { Query } from "@apollo/client/react/components"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  preferencesQuery,
  settingsClientQuery,
} from "domains/accountSettings/graphql"
import {
  solverQuery,
  assignmentWithClassesMutation,
  allGradesWithNewClassesQuery,
  moveStudentMutation,
  lockStudentMutation,
  lockStudentsMutation,
  updateSolverActiveGrade,
  solveActiveGradeMutation,
  boostCharacteristicMutation,
  boostSolutionMetricMutation,
  boostStudentMetricMutation,
  updateSelectedStudent,
  solverClientQuery,
  updateSelectedTeacherId,
  updateSolverUndoRedo,
  updateLastAssignmentMutation,
  favouriteAnAssignmentMutation,
  unfavouriteAnAssignmentMutation,
  updateFavouritedAssignmentMutation,
  pickAnAssignmentMutation,
} from "domains/solver/graphql"
import {
  studentFlagsClientQuery,
  updateStudentEditFlag,
} from "domains/students/graphql"

import { getNotificationsQuery } from "domains/notifications/graphql"

import { Loader, QueryError } from "components"
import { Solver } from "domains/solver/components"

import { NO_SOLUTION_FOUND } from "domains/solver/errorFields"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { handleSessionExpired, getSchoolId } from "util/app"
import { userProfileQuery } from "domains/auth/graphql"
import { activeCurrentClassesQuery } from "domains/classes/graphql"
import { characteristicsQuery } from "domains/characteristics/graphql"

import { NORMAL } from "domains/solver/constants"

const SolverPageComponent = props => {
  handleSessionExpired(props.error)

  const {
    loadingOne,
    loadingTwo,
    loadingThree,
    loadingFour,
    solverGrades,
    schoolSettings,
    settings,
    activeGrade,
  } = props

  if (loadingOne || loadingTwo || loadingThree || loadingFour) {
    return <Loader />
  }

  const solutionParams = {
    gradeId:
      activeGrade !== ""
        ? activeGrade.toString()
        : getPropertyIfDefined(solverGrades[0], "id"),
    schoolId: getSchoolId(),
  }

  const adminMode = settings.adminOnlyRequests

  const friendPreferences = schoolSettings.maxFriends > 0

  if (!isDefinedNotNull(solutionParams.gradeId)) {
    // There is no new classes
    return <Navigate to="/" replace />
  }

  const variables = { solution: solutionParams, adminOnly: adminMode }

  return (
    <Query
      // Workaround for https://github.com/apollographql/react-apollo/issues/2658
      key={JSON.stringify(variables)}
      query={solverQuery}
      variables={variables}
      fetchPolicy="network-only">
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loader />
        if (error) {
          // Check if it is a no solution found error (cold start)
          // If cold start, do NOT show the QueryError component
          if (
            !(
              isDefinedNotNull(error.message) &&
              error.message.includes(NO_SOLUTION_FOUND.key)
            )
          ) {
            return <QueryError error={error} refetch={refetch} />
          }
        }

        // For mutations needing to refetch the solver query
        const refetchQueries = [
          {
            query: solverQuery,
            variables: { solution: solutionParams },
          },
        ]

        return (
          <Solver
            data={getPropertyIfDefined(data, "solution")}
            activeGradeId={solutionParams.gradeId}
            refetchQueries={refetchQueries}
            adminMode={adminMode}
            friendPreferences={friendPreferences}
            refetch={refetch}
            solverMode={NORMAL}
            showTeachers={schoolSettings.showTeachersInSolver}
            showTeacherRequests
            showStudentRequests
            showMetrics
            showCharacteristics
            showFriendships
            {...props}
          />
        )
      }}
    </Query>
  )
}

export const SolverPage = compose(
  graphql(userProfileQuery, {
    props: ({ data: { myprofile: user } }) => ({
      user,
    }),
  }),
  graphql(allGradesWithNewClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, error, allGradesWithNewClasses } }) => ({
      loadingOne: loading,
      solverGrades: allGradesWithNewClasses,
      error,
    }),
  }),
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolSettings } }) => ({
      loadingTwo: loading,
      schoolSettings,
    }),
  }),
  graphql(solverClientQuery, {
    props: ({
      data: {
        solver: {
          selectedStudent,
          selectedTeacherId,
          activeGrade,
          undoMoves,
          redoMoves,
          lastAssignmentCached,
        },
      },
    }) => ({
      selectedStudent,
      selectedTeacherId,
      activeGrade,
      undoMoves,
      redoMoves,
      lastAssignmentCached,
    }),
  }),
  graphql(studentFlagsClientQuery, {
    props: ({ data: { studentFlags } }) => ({
      studentFlags,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(characteristicsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, schoolCharacteristics } }) => ({
      loadingThree: loading,
      schoolCharacteristics,
    }),
  }),

  graphql(getNotificationsQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, getNotifications } }) => ({
      loadingNotifications: loading,
      getNotifications,
    }),
  }),

  graphql(activeCurrentClassesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, activeCurrentClasses } }) => ({
      loadingFour: loading,
      activeCurrentClasses: activeCurrentClasses,
    }),
  }),
  graphql(solveActiveGradeMutation, { name: "solveActiveGradeMutation" }),
  graphql(boostCharacteristicMutation, { name: "boostCharacteristicMutation" }),
  graphql(boostSolutionMetricMutation, { name: "boostSolutionMetricMutation" }),
  graphql(boostStudentMetricMutation, { name: "boostStudentMetricMutation" }),
  graphql(moveStudentMutation, { name: "moveStudentMutation" }),
  graphql(lockStudentMutation, { name: "lockStudentMutation" }),
  graphql(lockStudentsMutation, { name: "lockStudentsMutation" }),
  graphql(updateSolverActiveGrade, { name: "updateSolverActiveGrade" }),
  graphql(updateSelectedStudent, { name: "updateSelectedStudent" }),
  graphql(updateSelectedTeacherId, { name: "updateSelectedTeacherId" }),
  graphql(updateSolverUndoRedo, { name: "updateSolverUndoRedo" }),
  graphql(updateLastAssignmentMutation, {
    name: "updateLastAssignment",
  }),
  graphql(updateStudentEditFlag, { name: "updateStudentEditFlag" }),
  graphql(favouriteAnAssignmentMutation, {
    name: "favouriteAnAssignmentMutation",
  }),
  graphql(unfavouriteAnAssignmentMutation, {
    name: "unfavouriteAnAssignmentMutation",
  }),
  graphql(updateFavouritedAssignmentMutation, {
    name: "updateFavouritedAssignmentMutation",
  }),
  graphql(pickAnAssignmentMutation, { name: "pickAnAssignmentMutation" }),
  graphql(assignmentWithClassesMutation, {
    name: "assignmentWithClassesMutation",
  })
)(SolverPageComponent)
