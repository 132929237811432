export const ADMINS = "ADMINS"
export const NEW_CLASSES = "NEW_CLASSES"
export const STUDENTS = "STUDENTS"
export const TEACHERS = "TEACHERS"
export const TEACHER_REQUESTS = "TEACHER_REQUESTS"
export const ADMIN_ONLY_TEACHER_REQUESTS = "ADMIN_ONLY_TEACHER_REQUESTS"
export const STUDENT_REQUESTS = "STUDENT_REQUESTS"
export const ADMIN_ONLY_STUDENT_REQUESTS = "ADMIN_ONLY_STUDENT_REQUESTS"
export const FRIENDSHIPS = "FRIENDSHIPS"
export const STUDENT_CHARACTERISTIC_RESPONSES =
  "STUDENT_CHARACTERISTIC_RESPONSES"
export const CURRENT_SOLUTIONS = "CURRENT_SOLUTIONS"
export const NEW_CLASSES_AS_TAG_ONLY_CHARACTERISTICS =
  "NEW_CLASSES_AS_TAG_ONLY_CHARACTERISTICS"

// Available current class options for Powerschool integration
export const HOME_ROOM = "HOME_ROOM"
export const SECTION_ENROLLMENT = "SECTION_ENROLLMENT"

// Availble options for syncing students from file
export const ADD_NEW = "ADD_NEW"
export const DELETE_MISSING = "DELETE_MISSING"
export const UPDATE_EXISTING = "UPDATE_EXISTING"
