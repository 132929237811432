// sorts the students by the active Grade selected then last name
import { sortBy, cloneDeep, orderBy } from "lodash"

export const sortSolverStudents = (newGradeId, students) => {
  students.sort((a, b) => {
    const aInActiveGrade = newGradeId === a.newGrade.id
    const bInActiveGrade = newGradeId === b.newGrade.id
    if (aInActiveGrade) {
      if (bInActiveGrade) {
        // if both are in the active grade
        return nameCompare(a, b)
      } else {
        return -1
      }
    } else {
      if (bInActiveGrade) {
        return 1
      } else {
        // if both are not in the active grade
        return nameCompare(a, b)
      }
    }
  })
}

export const immutableSortByName = people =>
  sortBy(people, ["lastName", "firstName"])

export const sortByCharacteristicResponse = (
  students,
  characteristicId,
  activeGradeId
) => {
  return orderBy(
    students,
    [
      // First sort by whether the student is in the activeGrade
      byActiveGrade(activeGradeId),
      // Then sort by the student's response value for the characteristic
      byCharacteristicResponse(characteristicId),
    ],
    ["asc", "desc"]
  )
}

const byActiveGrade = activeGradeId => {
  return student => (student.newGrade.id === activeGradeId ? 0 : 1)
}

const byCharacteristicResponse = characteristicId => {
  return student => {
    const response = student.characteristicResponses.find(
      ({ characteristic: { id } }) => id === characteristicId
    )

    return response ? response.value : -1
  }
}

export const sortByName = people => {
  people.sort(nameCompare)
}

export const nameCompare = (a, b) => {
  return a.lastName
    .concat(a.firstName)
    .localeCompare(b.lastName.concat(b.firstName))
}

export const sortStudentsByClass = students => {
  students.sort((a, b) => {
    const gradeCompare = a.currentGrade.order - b.currentGrade.order
    if (gradeCompare === 0) {
      const classCompare = a.currentClass
        ? b.currentClass
          ? a.currentClass.label.localeCompare(b.currentClass.label)
          : -1
        : 1
      if (classCompare === 0) {
        return nameCompare(a, b)
      } else {
        return classCompare
      }
    } else {
      return gradeCompare
    }
  })
}

export const sortGrades = grades => {
  grades.sort((a, b) => a.order - b.order)
}

const newGradeCompare = (a, b) => {
  if (a.schoolGrades.length === 0) return 1
  if (b.schoolGrades.length === 0) return -1

  const gradeCompare =
    a.schoolGrades.sort()[0].order - b.schoolGrades.sort()[0].order
  if (gradeCompare === 0) {
    return a.label.localeCompare(b.label, undefined, { numeric: true })
  }
  return gradeCompare
}

export const naturalSortByClassLabels = classes => {
  return classes.slice().sort((a, b) =>
    a.classDetails.label.localeCompare(b.classDetails.label, undefined, {
      numeric: true,
    })
  )
}

export const sortNewClasses = newClasses => {
  newClasses.sort(newGradeCompare)
}

export const sortSolverNewClasses = solverClasses => {
  return cloneDeep(solverClasses).sort((a, b) =>
    newGradeCompare(a.classDetails, b.classDetails)
  )
}

export const sortRequests = requests => {
  requests.sort((a, b) => {
    let difference = a.studentFromLastName.localeCompare(b.studentFromLastName)
    if (difference === 0) {
      difference = a.personToLastName.localeCompare(b.personToLastName)
    }
    return difference
  })
}

// Sort the two supplied teachers by...
// 1. Current Class Grade Order
// 2. Current Class Label
// 3. Teacher Last Name
export const compareTeachers = (teacherA, teacherB) => {
  const currentClassA = teacherA.currentClasses[0]
  const currentClassB = teacherB.currentClasses[0]

  // Begin by checking if both teachers have a current class
  if (currentClassA && currentClassB) {
    // Both classes exist, so we can delegate the result to `compareClasses`
    return compareClasses(teacherA, currentClassA, teacherB, currentClassB)
  }

  // If neither class exists, fallback to the teacher's last names
  if (!currentClassA && !currentClassB) {
    return teacherA.lastName.localeCompare(teacherB.lastName)
  }

  // At least one of the teachers has a class. Whomever that is goes up and
  // whomever doesn't goes down.
  return currentClassA ? -1 : 1
}

// subjectA and subjectB can be either a Teacher or a Student, or really
// any object that has a `lastName` property defined
export const compareClasses = (subjectA, classA, subjectB, classB) => {
  const gradeA = classA.grades[0]
  const gradeB = classB.grades[0]

  // If both subjects have a current class with a grade..
  if (gradeA && gradeB) {
    // If both grades have the same sort order, so we need to fall back to
    // class label
    if (gradeA.order === gradeB.order) {
      if (classA.label === classB.label) {
        // Well, geez! Same order and same class label! Now we need to
        // sort by the subject's lastName
        return subjectA.lastName.localeCompare(subjectB.lastName)
      } else {
        // The class labels are different, so we can sort by this
        return classA.label.localeCompare(classB.label)
      }
    }
    // Grade orders are not identical, so that's what we can safely sort by
    else {
      return gradeA.order - gradeB.order
    }
  }
}

// Mutably sorts an array of teachers using teacher and class sorting utils
// defined above
export const sortTeachers = teachers => {
  return teachers.sort(compareTeachers)
}

export const sortCurrentClassesByLabel = currentClasses => {
  // clone array to be able to sort
  return cloneDeep(currentClasses).sort((a, b) =>
    a.label.localeCompare(b.label)
  )
}

export const sortCurrentClasses = currentClasses => {
  // clone array to be able to sort
  return cloneDeep(currentClasses).sort(compareCurrentClasses)
}

// sort by grade order then class label
export const compareCurrentClasses = (classA, classB) => {
  // compare grade order first
  const lowestGradeA = classA.grades.sort((a, b) => a.order - b.order)[0]
  const lowestGradeB = classB.grades.sort((a, b) => a.order - b.order)[0]

  if (lowestGradeA.order === lowestGradeB.order) {
    // If lowest grade is the same, least number of grades win
    if (classA.grades.length === classB.grades.length) {
      // compare class labels if lowest grade and length of grades is the same
      return classA.label.localeCompare(classB.label)
    } else {
      return classA.grades.length - classB.grades.length
    }
  } else {
    return lowestGradeA.order - lowestGradeB.order
  }
}
