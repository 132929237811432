import React from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"

import { newClassesQuery } from "domains/classes/graphql"

import { ClassModal } from "components"
import { SolverClassHeader } from "./solverClassHeader"
import { SolverTeachers, StudentListItem } from "domains/solver/components"

import { isDefinedNotNull, getPropertyIfDefined } from "util/objUtil"
import { SCHOOL_ID } from "constants/storageTokens"

import { SHARED_READ_ONLY, NORMAL } from "domains/solver/constants"

import OutsideClickHandler from "react-outside-click-handler"

import { sortByCharacteristicResponse } from "util/sortUtil"

import { getCurrentClassResponses } from "util/characteristics"

const enrichStudents = (student, currentClassResponses) => {
  // We add the current class characteristicResponse to the student object
  const currentClassResponse = currentClassResponses.find(currentClass => {
    if (student.currentClass) {
      return currentClass.label === student.currentClass.label
    } else {
      return currentClass.id === "NO_CURRENT_CLASS"
    }
  })

  const characteristicResponses = student.characteristicResponses.concat([
    currentClassResponse,
  ])

  return { ...student, characteristicResponses }
}

export class SolverClass extends React.Component {
  constructor() {
    super()
    this.state = {
      classModal: false,
      idOfStudentBeingDragged: "",
    }
  }

  toggleModal = () => {
    const { clearSelectedStudent, updateSelectedTeacherId } = this.props
    clearSelectedStudent()
    updateSelectedTeacherId({
      variables: { selectedTeacherId: "" },
    })
    this.setState({ classModal: !this.state.classModal })
  }

  getItemStyle(isDragging, draggableStyle) {
    const border = isDragging ? "1px solid #d7dbe0" : ""

    return {
      ...draggableStyle,
      backgroundColor: "white",
      border: border,
    }
  }

  isStudentInClass = studentId => {
    return (
      this.props.classData.students.findIndex(s => s.id === studentId) !== -1
    )
  }

  isTeacherOfClass = teacherId => {
    return (
      this.props.classData.teachers.findIndex(t => t.id === teacherId) !== -1
    )
  }

  lockClassStudents = () => {
    const {
      lockStudentsMutation,
      refetchQueries,
      updateLockedStudentIdsOptimization,
    } = this.props
    let solutionId = this.props.solutionId
    let lockedState = this.calculateClassLockState()
    let studentIds = this.props.classData.students.map(student => student.id)

    lockStudentsMutation({
      variables: {
        studentIds: studentIds,
        lockedState: !lockedState,
        solutionId: solutionId,
      },
      refetchQueries: refetchQueries,
    }).then(() => {
      updateLockedStudentIdsOptimization(studentIds, !lockedState)
    })
  }

  calculateClassLockState = () => {
    let lockedStudentIds = (this.props.lockedStudentIds || []).map(
      id => `${id}`
    )
    let classStudentIds = this.props.classData.students.map(
      student => student.id
    )
    if (classStudentIds.length !== 0) {
      return classStudentIds.every(id => lockedStudentIds.includes(id))
    } else {
      return false
    }
  }

  render() {
    const {
      classStyle,
      classData,
      onLockStudent,
      onStudentClick,
      maxTeacherPerClass,
      schoolSettings,
      updateSelectedTeacherId,
      selectedTeacherId,
      selectedStudent,
      highlightStudents,
      activeGrade,
      activeCharacteristic,
      studentMetrics,
      lockedStudentIds,
      refetch,
      solverMode,
      showTeachers,
      showTeacherRequests,
      showStudentRequests,
      showFriendships,
      currentClasses,
      schoolCharacteristics,
      activeCurrentClasses,
      hasGenderX,
      isDropDisabled,
      sortByCharacteristicResponses,
      hasMax,
      showClassEntryCriteria,
      draggedStudent,
      studentsSelectedForDragging,
      toggleTeacherPanel,
      teacherPanelIsOpen,
    } = this.props

    const {
      key,
      classDetails: {
        id,
        label,
        schoolGrades,
        maxClassSize,
        maxSizePerGrade,
        entryCriteriaCharacteristicResponse,
        entryCriteriaStudentAttribute,
        entryCriteriaNullResponse,
      },
      teachers,
      students,
    } = classData

    // get the teacher constraints from the selected student
    const teacherConstraints = getPropertyIfDefined(
      selectedStudent,
      "teacherConstraints"
    )

    const readMode = solverMode === SHARED_READ_ONLY

    const activeCharacteristicId = activeCharacteristic.characteristic
      ? activeCharacteristic.characteristic.id
      : null

    // The activeCharacteristic prop is either an actual characteristic from graphql, {id: "all"},
    // or {id: ''} so we take care of that mess here.
    const activeCharacteristic2 = activeCharacteristic.characteristic
      ? activeCharacteristic.characteristic
      : null

    // If displaying all characteristics
    const showAllCharacteristics = activeCharacteristic.id === "all"

    const currentClassResponses = getCurrentClassResponses(currentClasses)

    const enrichedStudents = students.map(student => {
      // We add the current class characteristicResponse to the student object
      const currentClassResponse = currentClassResponses.find(currentClass => {
        if (student.currentClass) {
          return currentClass.label === student.currentClass.label
        } else {
          return currentClass.id === "NO_CURRENT_CLASS"
        }
      })

      const characteristicResponses = student.characteristicResponses.concat([
        currentClassResponse,
      ])

      return { ...student, characteristicResponses }
    })

    const sortedStudents = sortByCharacteristicResponses
      ? sortByCharacteristicResponse(
          enrichedStudents,
          activeCharacteristicId,
          activeGrade
        )
      : enrichedStudents

    const enrichedStudentsSelectedForDragging = studentsSelectedForDragging.map(
      student => enrichStudents(student, currentClassResponses)
    )

    // enrichedStudentsSelectedForDragging is separated into studentsToHide and draggedStudent. This is because of how the react-beautiful-dnd library works.
    // When dragging an item, the library automatically removes the dragged item from the list it is in currently. However the library has no concept of
    // the additional items that user wants to drag, which means we have to hide them ourselves.
    const studentsToHide = draggedStudent
      ? enrichedStudentsSelectedForDragging.filter(
          student => student.id !== draggedStudent.id
        )
      : []

    return (
      <div className="c-solver-class d-flex flex-column" style={classStyle}>
        <SolverClassHeader
          students={enrichedStudents}
          lockState={this.calculateClassLockState()}
          lockClassStudents={this.lockClassStudents}
          editable={schoolSettings.showTeachersInSolver}
          classData={classData}
          toggleEditClassModal={this.toggleModal}
          hasGenderX={hasGenderX}
          readMode={solverMode !== NORMAL}
          activeGradeId={activeGrade}
          hasMax={hasMax}
          activeCharacteristic={activeCharacteristic2}
          currentClassResponses={currentClassResponses}
        />
        {showTeachers && (
          <SolverTeachers
            updateSelectedTeacherId={updateSelectedTeacherId}
            teacherConstraints={teacherConstraints}
            maxTeacherPerClass={maxTeacherPerClass}
            isStudentInClass={this.isStudentInClass}
            selectedStudentId={selectedStudent.studentId}
            teachers={teachers}
            selectedTeacherId={selectedTeacherId}
            toggleModal={this.toggleModal}
            readMode={solverMode !== NORMAL}
            toggleTeacherPanel={toggleTeacherPanel}
            teacherPanelIsOpen={teacherPanelIsOpen}
          />
        )}
        {/* Student List */}
        <Droppable droppableId={id} index={key} isDropDisabled={isDropDisabled}>
          {(provided, _snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="c-solver-class__students">
                <OutsideClickHandler
                  onOutsideClick={e => {
                    // We want the user to be able to click on either blank space inside the class column or the space outside all of the class columns to deselect a student
                    // By checking specific classes and not just excluding the student node itself we can still allow the user to interact with other controls on the page (some of which depend on having a selected student) without interrupting their click event
                    if (
                      e.target.classList.contains("u-layout-component-grow") ||
                      e.target.classList.contains("c-solver-class__students")
                    ) {
                      this.props.clearSelectedStudent()
                      this.props.clearStudentsToBeDragged()
                    }
                  }}>
                  {sortedStudents.map((student, index) => {
                    // check if student has been dropped
                    const studentDropped = highlightStudents.includes(
                      student.id
                    )
                    const studentToBeDragged =
                      studentsSelectedForDragging.filter(
                        draggedStudent => draggedStudent.id === student.id
                      ).length > 0

                    const lockState = isDefinedNotNull(lockedStudentIds)
                      ? lockedStudentIds.includes(parseInt(student.id, 10))
                      : false

                    // Check for friend constraint
                    let friendConstraint = {}
                    if (showFriendships) {
                      selectedStudent.friends.forEach(f => {
                        if (
                          f.studentTo.id === student.id &&
                          student.id !== selectedStudent.studentId
                        ) {
                          friendConstraint = {
                            isFriend: true,
                            isMet: this.isStudentInClass(
                              students,
                              selectedStudent.studentId
                            ),
                          }
                        }
                      })
                    }

                    let studentConstraint
                    if (showStudentRequests) {
                      selectedStudent.studentConstraints.forEach(c => {
                        if (student.id === selectedStudent.studentId) {
                          return
                        }
                        if (
                          c.studentTo.id === student.id ||
                          c.studentFrom.id === student.id
                        ) {
                          studentConstraint = {
                            mandatory: c.mandatory,
                            pair: c.pair,
                            isMet: c.pair
                              ? this.isStudentInClass(selectedStudent.studentId)
                              : !this.isStudentInClass(
                                  selectedStudent.studentId
                                ),
                          }
                        }
                      })
                    }

                    // get the metrics related to this student
                    let currentStudentMetrics = {}
                    if (studentMetrics) {
                      currentStudentMetrics = studentMetrics.find(
                        studentMetric => studentMetric.student.id === student.id
                      )
                    }

                    let teacherConstraint
                    if (showTeacherRequests && student.teacherConstraints) {
                      student.teacherConstraints.forEach(c => {
                        if (c.teacherToId.toString() === selectedTeacherId) {
                          teacherConstraint = {
                            mandatory: c.mandatory,
                            pair: c.pair,
                            isMet: c.pair
                              ? this.isTeacherOfClass(selectedTeacherId)
                              : !this.isTeacherOfClass(selectedTeacherId),
                          }
                        }
                      })
                    }

                    const inActiveGrade =
                      activeGrade.toString() === student.newGrade.id

                    const studentListItem = (
                      <StudentListItem
                        classId={id}
                        onStudentClick={onStudentClick}
                        lockState={lockState}
                        studentMetrics={currentStudentMetrics}
                        onLockStudent={onLockStudent}
                        studentDropped={studentDropped}
                        studentToBeDragged={studentToBeDragged}
                        friendConstraint={friendConstraint}
                        studentConstraint={studentConstraint}
                        teacherConstraint={teacherConstraint}
                        inActiveGrade={inActiveGrade}
                        activeCharacteristic={activeCharacteristic2}
                        showAllCharacteristics={showAllCharacteristics}
                        selectedTeacherId={selectedTeacherId}
                        student={student}
                        schoolSettings={schoolSettings}
                        selectedStudentId={selectedStudent.studentId}
                        readMode={readMode}
                        showTeacherRequests={showTeacherRequests}
                        showStudentRequests={showStudentRequests}
                        showFriendships={showFriendships}
                        showTeachers={showTeachers}
                        showClassEntryCriteria={showClassEntryCriteria}
                      />
                    )

                    const listToMapOver = draggedStudent
                      ? enrichedStudentsSelectedForDragging
                      : []

                    const multipleStudentListItem = listToMapOver.map(
                      (student, index) => {
                        const inActiveGrade =
                          activeGrade.toString() === student.newGrade.id

                        return (
                          <div key={index}>
                            <StudentListItem
                              activeCharacteristic={activeCharacteristic2}
                              student={student}
                              selectedStudentId={student.id}
                              studentToBeDragged={studentToBeDragged}
                              inActiveGrade={inActiveGrade}
                            />
                          </div>
                        )
                      }
                    )

                    return (
                      <Draggable
                        key={student.id}
                        index={index}
                        draggableId={student.id}
                        // locked students cannot be dragged
                        isDragDisabled={readMode || lockState}>
                        {(provided, snapshot) => {
                          const filteredStudents = studentsToHide.filter(
                            st => st.id === student.id
                          )
                          const studentToBeHidden = filteredStudents.length > 0

                          let itemToDrag
                          if (
                            draggedStudent &&
                            studentToBeHidden &&
                            student.id !== draggedStudent.id
                          ) {
                            itemToDrag = null
                          } else if (
                            draggedStudent &&
                            studentsToHide.length > 0 &&
                            snapshot.isDragging
                          ) {
                            itemToDrag = <>{multipleStudentListItem}</>
                          } else {
                            itemToDrag = studentListItem
                          }

                          return (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={this.getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              onClick={this.onClick}>
                              {itemToDrag}
                            </div>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </OutsideClickHandler>
              </div>
            )
          }}
        </Droppable>
        {this.state.classModal && (
          <ClassModal
            isOpen={this.state.classModal}
            toggle={this.toggleModal}
            disableInput
            id={id}
            label={label}
            maxClassSize={maxClassSize}
            maxSizePerGrade={maxSizePerGrade}
            schoolGrades={schoolGrades}
            teachers={teachers}
            heading="Edit Class"
            buttonText="Save Class"
            edit={true}
            canDelete={false}
            refetch={refetch}
            refetchQueries={[
              {
                query: newClassesQuery,
                variables: { schoolId: sessionStorage.getItem(SCHOOL_ID) },
              },
            ]}
            characteristics={schoolCharacteristics}
            currentClasses={activeCurrentClasses}
            entryCriteriaCharacteristicResponse={
              entryCriteriaCharacteristicResponse
            }
            entryCriteriaStudentAttribute={entryCriteriaStudentAttribute}
            entryCriteriaNullResponse={entryCriteriaNullResponse}
          />
        )}
      </div>
    )
  }
}
