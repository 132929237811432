import React from "react"

import {
  AttributeLabel,
  CharacteristicCircle,
  CharacteristicSummaryTooltip,
} from "domains/solver/components"
import { ClassBadge } from "components"

import {
  FRIENDSHIP_PREFERENCES,
  MANDATORY_REQUESTS,
  IMPORTANT_REQUESTS,
  DIFFERENT_TEACHER,
  NORMAL,
  SHARED_WRITABLE,
  CLASS_ENTRY_CRITERIA,
} from "domains/solver/constants"
import { getPropertyIfDefined } from "util/objUtil"
import { genderColor } from "util/studentUtil"

export class SelectedStudentDetails extends React.Component {
  constructor(props) {
    super(props)

    // Set up a ref which we can use to get the
    // comments div node
    this.commentsRef = null
    this.setCommentsRef = ref => {
      this.commentsRef = ref
    }

    this.state = {
      commentExpanded: false,
      commentOverflowed: false,
    }
  }

  componentDidUpdate() {
    // Update the comments div
    const newCommentOverflowed = this.isEllipsisActive(this.commentsRef)
    if (newCommentOverflowed !== this.state.commentOverflowed) {
      this.updateCommentOverflowed(newCommentOverflowed)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { updateSelectedStudent } = nextProps
    if (
      this.props.id !== nextProps.id ||
      this.props.friends !== nextProps.friends
    ) {
      // if there is a change in id or friends, update the selected student
      const {
        id,
        firstName,
        lastName,
        friends,
        teacherConstraints,
        studentConstraints,
      } = nextProps

      updateSelectedStudent({
        variables: {
          id,
          firstName,
          lastName,
          friends,
          teacherConstraints,
          studentConstraints,
        },
      })
    }
  }

  componentDidMount() {
    if (this.props.id) {
      // if there is a change in id, update the selected student
      const {
        updateSelectedStudent,
        id,
        firstName,
        lastName,
        friends,
        teacherConstraints,
        studentConstraints,
      } = this.props

      this.updateCommentOverflowed(this.isEllipsisActive(this.commentsRef))

      updateSelectedStudent({
        variables: {
          id,
          firstName,
          lastName,
          friends,
          teacherConstraints,
          studentConstraints,
        },
      })
    }
  }

  updateCommentOverflowed(value) {
    this.setState({ commentOverflowed: value })
  }

  isEllipsisActive(e) {
    if (e) {
      return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth
    } else {
      return false
    }
  }

  render() {
    const {
      id,
      toggle,
      firstName,
      lastName,
      gender,
      currentClass,
      comments,
      characteristicResponses,
      studentMetrics,
      onBoostStudentMetricClick,
      studentBoostDifferences = {},
      friendPreferences,
      differentTeacherSetting,
      solverMode,
      showTeacherRequests,
      showStudentRequests,
      showCharacteristics,
      showClassEntryCriteria,
    } = this.props

    const { commentExpanded, commentOverflowed } = this.state

    const canEdit = solverMode === NORMAL || solverMode === SHARED_WRITABLE

    const sortedCharacteristicResponses = [...characteristicResponses].sort(
      (a, b) => a.characteristic.priority - b.characteristic.priority
    )

    return (
      <div className="c-selected-student-details">
        <div className="d-flex flex-column border-bottom py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div
              className={`c-selected-student-details__student-overview w-75 text-truncate ${genderColor(
                gender
              )}`}>
              {toggle && (
                <i
                  className="c-selected-student-details__student-overview__icon fa fa-external-link-alt cursor-pointer"
                  onClick={toggle}>
                  &#xf08e;
                </i>
              )}
              <span className="c-selected-student-details__student-overview__name">{`${firstName} ${lastName}`}</span>
            </div>
            <ClassBadge label={getPropertyIfDefined(currentClass, "label")} />
          </div>
          {showCharacteristics && (
            <>
              <div
                data-tooltip-id={`selected-student-${id}`}
                className="c-selected-student-details__characteristics d-flex mt-2">
                {sortedCharacteristicResponses &&
                  sortedCharacteristicResponses.map((response, index) => (
                    <CharacteristicCircle key={index} {...response} />
                  ))}
              </div>
              <CharacteristicSummaryTooltip
                id={`selected-student-${id}`}
                characteristicResponses={sortedCharacteristicResponses}
                className="c-characteristic-tooltip p-0"
                place="right"
                variant="light"
                float
              />
            </>
          )}
          {comments && (
            <div className="d-flex mt-2">
              <div
                ref={this.setCommentsRef}
                className={`c-selected-student-details__student-overview__comments-${
                  commentExpanded ? "expanded" : "collapsed"
                }`}>
                {comments}
              </div>
              {(commentOverflowed || commentExpanded) && (
                <i
                  className={`ml-2 color-grey-mid fa ${
                    commentExpanded ? "fa-chevron-up" : "fa-chevron-down"
                  }`}
                  onClick={() => {
                    this.setState(prevState => ({
                      commentExpanded: !prevState.commentExpanded,
                    }))
                  }}
                />
              )}
            </div>
          )}
        </div>
        {(showStudentRequests || showTeacherRequests) && studentMetrics && (
          <div>
            <AttributeLabel
              label="Friendship Preferences"
              icon="_badge bg-friendships mr-1"
              className="mt-4"
              showMetric={canEdit}
              disabled={
                studentMetrics.friendshipPreferences.count ===
                  studentMetrics.friendshipPreferences.total ||
                !friendPreferences
              }
              count={
                friendPreferences
                  ? studentMetrics.friendshipPreferences.count
                  : "-"
              }
              total={
                friendPreferences
                  ? studentMetrics.friendshipPreferences.total
                  : "-"
              }
              onClick={
                canEdit &&
                (e => {
                  onBoostStudentMetricClick(e, FRIENDSHIP_PREFERENCES)
                })
              }
              difference={studentBoostDifferences.friendshipPreferences}
            />
            <AttributeLabel
              label="Mandatory Requests"
              icon="_badge bg-requests-mandatory mr-1"
              className="mt-4"
              count={studentMetrics.mandatoryRequests.count}
              total={studentMetrics.mandatoryRequests.total}
              showMetric={canEdit}
              onClick={
                canEdit &&
                (e => {
                  onBoostStudentMetricClick(e, MANDATORY_REQUESTS)
                })
              }
              difference={studentBoostDifferences.mandatoryRequests}
            />
            <AttributeLabel
              label="Important Requests"
              icon="_badge bg-requests-important mr-1"
              className="mt-4"
              count={studentMetrics.importantRequests.count}
              total={studentMetrics.importantRequests.total}
              showMetric={canEdit}
              onClick={
                canEdit &&
                (e => {
                  onBoostStudentMetricClick(e, IMPORTANT_REQUESTS)
                })
              }
              difference={studentBoostDifferences.importantRequests}
            />
            {showClassEntryCriteria && (
              <AttributeLabel
                label="Class Entry Criteria"
                icon="_badge bg-class-entry-criteria mr-1"
                className="mt-4"
                count={studentMetrics.classEntryCriteria}
                showMetric={canEdit}
                onClick={
                  canEdit &&
                  (e => {
                    onBoostStudentMetricClick(e, CLASS_ENTRY_CRITERIA)
                  })
                }
              />
            )}
            {canEdit && (
              <AttributeLabel
                label="Different Teacher"
                icon="_badge bg-requests-teacher mr-1"
                className="mt-4"
                count={studentMetrics.differentTeacher}
                showMetric={canEdit}
                onClick={
                  canEdit &&
                  (e => {
                    onBoostStudentMetricClick(e, DIFFERENT_TEACHER)
                  })
                }
                disabled={!differentTeacherSetting}
                differentTeacher={studentBoostDifferences.differentTeacher}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}
