import { gql } from "@apollo/client"

export const surveyStudentsQuery = gql`
  query ($teacherId: ID!, $adminOnly: Boolean!) {
    surveyStudents(teacherId: $teacherId) {
      id
      firstName
      lastName
      gender
      studentCode
      comments
      currentClass {
        id
        label
      }
      currentGrade {
        id
        code
        order
        label
      }
      newGrade {
        id
        code
        label
      }
      constraintsCount(adminOnly: $adminOnly)
      characteristicResponses {
        id
        description
        value
        characteristicId
        label
      }
      friends {
        id
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
    }
  }
`

export const studentsQuery = gql`
  query ($adminOnly: Boolean, $schoolId: ID!) {
    students(schoolId: $schoolId) {
      id
      firstName
      lastName
      gender
      studentCode
      comments
      currentClass {
        id
        label
      }
      currentGrade {
        id
        code
        order
        label
      }
      newGrade {
        id
        code
        label
      }
      newClass {
        id
        label
      }
      constraintsCount(adminOnly: $adminOnly)
      characteristicResponses {
        id
        description
        value
        characteristicId
        label
      }
      friends {
        id
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      studentConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
        studentFrom {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      teacherConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        teacherTo {
          id
          firstName
          lastName
        }
        studentFrom {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const selectedStudentsQuery = gql`
  query ($schoolId: ID!, $ids: [ID!]!, $adminOnly: Boolean) {
    selectedStudents(schoolId: $schoolId, ids: $ids) {
      id
      firstName
      lastName
      gender
      studentCode
      comments
      currentClass {
        id
        label
      }
      currentGrade {
        id
        code
        order
        label
      }
      newGrade {
        id
        code
        label
      }
      newClass {
        id
        label
      }
      constraintsCount(adminOnly: $adminOnly)
      characteristicResponses {
        id
        description
        value
        characteristicId
        label
      }
      friends {
        id
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      studentConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
        studentFrom {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      teacherConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        teacherTo {
          id
          firstName
          lastName
        }
        studentFrom {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const studentQuery = gql`
  query ($id: ID!, $adminOnly: Boolean) {
    student(id: $id) {
      id
      firstName
      lastName
      gender
      studentCode
      comments
      constraintsCount(adminOnly: $adminOnly)
      currentGrade {
        id
        code
        label
      }
      currentClass {
        id
        label
      }
      newGrade {
        id
        code
        label
      }
      newClass {
        id
        label
      }
      characteristicResponses {
        id
        characteristicId
        value
      }
      friends {
        id
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      studentConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        studentTo {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
        studentFrom {
          id
          firstName
          lastName
          currentClass {
            id
            label
          }
        }
      }
      teacherConstraints(adminOnly: $adminOnly) {
        id
        constraintId
        description
        mandatory
        pair
        adminOnly
        teacherTo {
          id
          firstName
          lastName
        }
        studentFrom {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const maxFriendsQuery = gql`
  query ($schoolId: ID!) {
    schoolSettings(schoolId: $schoolId) {
      id
      maxFriends
    }
  }
`

export const deleteStudentMutation = gql`
  mutation ($id: ID!) {
    deleteStudent(id: $id) {
      id
    }
  }
`

export const createOrUpdateStudentMutation = gql`
  mutation createOrUpdateStudent($student: StudentParams!) {
    createOrUpdateStudent(student: $student) {
      id
    }
  }
`

export const createStudentMutation = gql`
  mutation createStudent($student: CreateStudentParams!) {
    createStudent(student: $student) {
      id
    }
  }
`

export const createOrUpdateInactiveStudentMutation = gql`
  mutation createOrUpdateInactiveStudent($student: CreateStudentParams!) {
    createOrUpdateInactiveStudent(student: $student) {
      id
    }
  }
`

export const updateNewGradeForStudents = gql`
  mutation updateNewGradeForStudents(
    $updateNewGradeParams: UpdateNewGradeParams!
  ) {
    updateNewGradeForStudents(updateNewGradeParams: $updateNewGradeParams)
  }
`

export const studentNameQuery = gql`
  query ($schoolId: ID!) {
    students(schoolId: $schoolId) {
      id
      firstName
      lastName
      currentClass {
        id
        label
      }
    }
  }
`

export const updateStudentCharacteristicResponses = gql`
  mutation updateStudentCharacteristicResponses(
    $characteristicResponseIds: [ID!]!
    $studentId: ID!
  ) {
    updateStudentCharacteristicResponses(
      characteristicResponseIds: $characteristicResponseIds
      studentId: $studentId
    ) {
      id
    }
  }
`

export const updateInsensitiveStudentCharacteristicResponses = gql`
  mutation updateInsensitiveStudentCharacteristicResponses(
    $characteristicResponseIds: [ID!]!
    $studentId: ID!
  ) {
    updateInsensitiveStudentCharacteristicResponses(
      characteristicResponseIds: $characteristicResponseIds
      studentId: $studentId
    ) {
      id
    }
  }
`

export const bulkUploadStudentsMutation = gql`
  mutation ($schoolId: ID!, $file: Upload!) {
    bulkUploadStudents(schoolId: $schoolId, file: $file) {
      id
      status
    }
  }
`

export const studentFlagsClientQuery = gql`
  query {
    studentFlags @client {
      studentNewGradeChange
      studentUpdated
      deletedStudent
    }
  }
`

export const updateStudentEditFlag = gql`
  mutation updateStudentEditFlag($type: String!, $value: Boolean!) {
    updateStudentEditFlag(type: $type, value: $value) @client {
      studentNewGradeChange
    }
  }
`

export const deleteAllStudentNotesMutation = gql`
  mutation ($schoolId: ID!) {
    deleteAllStudentNotes(schoolId: $schoolId)
  }
`

export const deleteStudentNotesByCurrentGradesMutation = gql`
  mutation ($schoolId: ID!, $currentGradeIds: [ID!]!) {
    deleteStudentNotesByCurrentGrades(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
    )
  }
`

export const deleteStudentsByCurrentGradesMutation = gql`
  mutation ($schoolId: ID!, $currentGradeIds: [ID!]!) {
    deleteStudentsByCurrentGrades(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
    )
  }
`

export const deleteStudentsByCurrentClassesMutation = gql`
  mutation ($schoolId: ID!, $currentClassIds: [ID!]!) {
    deleteStudentsByCurrentClasses(
      schoolId: $schoolId
      currentClassIds: $currentClassIds
    )
  }
`

export const removeStudentsByCurrentGradesMutation = gql`
  mutation ($schoolId: ID!, $currentGradeIds: [ID!]!) {
    deactivateStudentsByCurrentGrades(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
    )
  }
`

export const removeStudentsByCurrentClassesMutation = gql`
  mutation ($schoolId: ID!, $currentClassIds: [ID!]!) {
    deactivateStudentsByCurrentClasses(
      schoolId: $schoolId
      currentClassIds: $currentClassIds
    )
  }
`

export const uiStudentPageQuery = gql`
  {
    studentPage @client {
      filterId
    }
  }
`

export const studentHistoriesQuery = gql`
  query studentHistories($schoolId: ID!) {
    studentHistories(schoolId: $schoolId) {
      file {
        id
        fileName
        link
        timestamp
      }
      id
    }
  }
`

export const switchStudentNamesByCurrentGradesMutation = gql`
  mutation ($schoolId: ID!, $currentGradeIds: [ID!]!) {
    switchStudentNamesByCurrentGrades(
      schoolId: $schoolId
      currentGradeIds: $currentGradeIds
    )
  }
`

export const switchStudentNamesMutation = gql`
  mutation ($schoolId: ID!) {
    switchStudentNames(schoolId: $schoolId)
  }
`

export const deactivateAllStudentsMutation = gql`
  mutation ($schoolId: ID!) {
    deactivateAllStudents(schoolId: $schoolId)
  }
`
