import React from "react"
import {
  RouterProvider,
  useNavigate,
  Navigate,
  createBrowserRouter,
  useMatch,
} from "react-router-dom"

import { useAuth0 } from "domains/auth/auth0Wrapper"
import { CautionModal, InlineError } from "components"
import { Nav, ErrorBoundary } from "domains/app/components"
import { UploadStart as CharacteristicsUploadStart } from "domains/characteristicsImport/UploadStart"
import {
  FriendshipPreferencesUpload,
  FriendshipPreferencesMapStudents,
} from "domains/friendshipPreferencesImport"
import { MapColumns as CharacteristicsMapColumns } from "domains/characteristicsImport/MapColumns"
import { MapResponseLabels as CharacteristicsMapResponseLabels } from "domains/characteristicsImport/MapResponseLabels"
import { StudentNotesUpload } from "domains/studentNotesImport/StudentNotesUpload"
import { MapColumns as StudentNotesMapColumns } from "domains/studentNotesImport/MapColumns"
import { ImportSolutionPage } from "domains/solutionsImport/ImportSolutionPage"
import { ImportSolutionMapColumns } from "domains/solutionsImport/ImportSolutionMapColumns"
import {
  TeacherRequestsImport,
  StudentRequestsImport,
} from "domains/requests/components"

import { MapColumns as TeachersMapColumns } from "domains/teachersImport/MapColumns"
import { MapClassLabels as TeachersMapClassLabels } from "domains/teachersImport/MapClassLabels"
import { UploadStart as TeachersUploadStart } from "domains/teachersImport/UploadStart"

import { useQuery } from "@apollo/client"

import { getSchoolId } from "util/app"
import { hasAcceptedTerms } from "util/userUtil"
import { isNonEmptyArray } from "util/array"
import { MFA_CLAIM } from "domains/auth/constants"

import {
  AcceptInvitationPage,
  AccountSettingsPage,
  AuthenticationErrorPage,
  BillingSettingsPage,
  CharacteristicsPage,
  ClassesPage,
  ColumnMappingPage,
  ConsentPage,
  DashboardPage,
  GradeLabelsPage,
  GradeSystemPage,
  IdChangeWarningPage,
  ImportSuccessPage,
  UnavailableSurveyPage,
  LoginPage2,
  LogoutPage,
  ReportClassPage,
  ReportSchoolPage,
  ReportSociogramPage,
  RequestsPage,
  RequireMfaPage,
  SharedSolutionLoginPage,
  SignUpPage,
  SignUpSuccessPage,
  SolverPage,
  StudentsPage,
  SuperAdminPage,
  SurveyLoginPage,
  TeachersPage,
  UnauthorizedPage,
  UpdateUserDetailsPage,
  UserProfilePage,
  VersionPage,
} from "./pages"

import { userProfileQuery } from "domains/auth/graphql"
import { schoolQuery, preferencesQuery } from "domains/accountSettings/graphql"
import { AccountTypeProvider } from "config/accountTypeContext"

const ErrorModal = ({ error, user }) => {
  const navigate = useNavigate()

  if (error.message === "GraphQL error: unauthorized") {
    return <Navigate to="/Unauthorized" replace />
  }

  const logout = () => navigate("/Logout")

  const messageFooter = (
    <>
      If the issue persists, please take a screenshot of this message and send
      it to
      <a href="mailto:support@classsolver.com" className="ml-1 color-blue-mid">
        support@classsolver.com
      </a>
    </>
  )

  let headingText
  let message

  if (error.networkError && error.networkError.message === "Failed to fetch") {
    headingText = "Network issue"
    message = (
      <span>
        Sorry, we were unable to connect you to Class Solver.
        <br />
        <br />
        Please contact your school's network administrator to ensure Class
        Solver is not blocked by your school's Proxy or Firewall.
        <br />
        <br />
        {messageFooter}
        <br />
      </span>
    )
  } else if (error.message === "unauthorized") {
    headingText = "Unauthorized User"
    message = (
      <span>
        Your session may have expired or there is no account registered with the
        email address provided:
        <br />
        <br />
        <InlineError text={user.email} />
        <br />
        <br />
        Please check that the email address is correct and try it again.
        <br />
        <br />
        If you are using “Log in with Google”, please ensure you are logged in
        with the Google account registered with Class Solver.
        <br />
        <br />
        If you are a teacher, you might not have a registered account, please
        contact your school administrator.
        <br />
        <br />
        {messageFooter}
        <br />
      </span>
    )
  } else {
    headingText = "Something went wrong ..."
    message = (
      <span>
        Sorry, we have encountered an issue logging you into Class Solver.
        <br />
        <br />
        Please completely close your browser window and try again.
        <br />
        <br />
        {messageFooter}
        <br />
        <br />
        <InlineError text={error.message} />
      </span>
    )
  }

  return (
    <CautionModal
      isOpen
      toggle={logout}
      onButtonClick={logout}
      buttonText="Back to Login"
      showCancel={false}
      heading={headingText}
      text={message}
    />
  )
}

const CheckPublicAuth = ({ children, restricted }) => {
  const { isAuthenticated, authenticationError } = useAuth0()
  if (authenticationError) {
    return <AuthenticationErrorPage authenticationError={authenticationError} />
  }

  if (isAuthenticated && restricted) {
    return <Navigate to="/" replace />
  }

  return children
}

const CheckPrivateAuth = ({ children, locked }) => {
  const { isAuthenticated, authenticationError, user } = useAuth0()
  if (authenticationError) {
    return <AuthenticationErrorPage authenticationError={authenticationError} />
  }

  if (!isAuthenticated) {
    return <Navigate to="/Login" replace />
  }

  return (
    <AuthenticatedComponent user={user} locked={locked}>
      {children}
    </AuthenticatedComponent>
  )
}

const AuthenticatedComponent = ({ children, locked, user }) => {
  const userProfilePath = useMatch("/UserProfile")

  // Retrieve the user profile to check authorization
  const {
    data: userProfileData,
    loading: loadingUser,
    error: userProfileError,
  } = useQuery(userProfileQuery, { fetchPolicy: "network-only" })

  const { data: schoolSettingsData, loading: loadingSchoolSettings } = useQuery(
    preferencesQuery,
    {
      variables: { schoolId: getSchoolId() },
      fetchPolicy: "network-only",
      skip: loadingUser,
    }
  )

  const { data: schoolData, loading: loadingSchool } = useQuery(schoolQuery, {
    variables: { schoolId: getSchoolId() },
    fetchPolicy: "network-only",
    skip: loadingUser || !getSchoolId(),
  })

  if (loadingUser || loadingSchoolSettings || loadingSchool) return null

  if (userProfileError)
    return <ErrorModal error={userProfileError} user={user} />

  const myprofile = userProfileData.myprofile

  // Lock routes by default unless explicitly set to false
  const routeLocked = locked === undefined ? true : locked

  const routeIsLocked =
    routeLocked &&
    schoolSettingsData &&
    schoolSettingsData.schoolSettings.newAccountLock === true

  const hasInvitation = isNonEmptyArray(myprofile.schoolInvitations)

  const missingUserDetails =
    myprofile.firstName === null || myprofile.lastName === null

  // Check whether the selectedSchool requires MFA
  const selectedSchool =
    myprofile.schools.length === 1
      ? myprofile.schools[0]
      : myprofile.schools.find(school => school.id === getSchoolId())

  // Only check for this is we are not in the `/UserProfile` page
  if (
    !userProfilePath &&
    selectedSchool &&
    selectedSchool.requireMfa &&
    !user[MFA_CLAIM]
  ) {
    return <Navigate to="/RequireMfa" />
  }

  if (!hasAcceptedTerms(myprofile)) {
    return <Navigate to="/Consent" />
  } else if (missingUserDetails) {
    return <Navigate to="/UpdateUserDetails" />
  } else if (hasInvitation) {
    return <Navigate to="/AcceptInvitation" />
  } else if (routeIsLocked) {
    return <Navigate to="/" replace />
  } else {
    return (
      <AccountTypeProvider school={schoolData?.school}>
        {children}
      </AccountTypeProvider>
    )
  }
}

const publicRoutes = [
  { path: "/AcceptInvitation", component: AcceptInvitationPage },
  { path: "/Consent", component: ConsentPage },
  { path: "/Login", restricted: true, component: LoginPage2 },
  { path: "/Logout", component: LogoutPage },
  { path: "/RequireMfa", component: RequireMfaPage },
  { path: "/SharedSolution/:token", component: SharedSolutionLoginPage },
  {
    path: "/SharedSolution/:token/:subPath",
    component: SharedSolutionLoginPage,
  },
  { path: "/Signup", restricted: true, component: SignUpPage },
  { path: "/Signup/Success", restricted: true, component: SignUpSuccessPage },
  { path: "/Surveys/:token", component: SurveyLoginPage },
  { path: "/Unauthorized", component: UnauthorizedPage },
  { path: "/UpdateUserDetails", component: UpdateUserDetailsPage },
  { path: "/version", locked: false, component: VersionPage },
  {
    path: "/UnavailableSurvey",
    locked: false,
    component: UnavailableSurveyPage,
  },
].map(route => {
  const Component = route.component
  return {
    path: route.path,
    element: (
      <CheckPublicAuth restricted={route.restricted}>
        <Component />
      </CheckPublicAuth>
    ),
    errorElement: <ErrorBoundary />,
  }
})

const privateRoutes = [
  { path: "/", locked: false, component: DashboardPage },
  { path: "/AccountSettings", locked: false, component: AccountSettingsPage },
  { path: "/BillingSettings", locked: false, component: BillingSettingsPage },
  { path: "/Characteristics", locked: false, component: CharacteristicsPage },
  { path: "/Classes", component: ClassesPage },
  { path: "/UserProfile", locked: false, component: UserProfilePage },
  { path: "/Requests", component: RequestsPage },
  {
    path: "/AccountSettings/TransferStudentRequests",
    locked: false,
    component: StudentRequestsImport,
  },
  {
    path: "/Requests/TeacherRequests/Transfer",
    locked: false,
    component: TeacherRequestsImport,
  },
  { path: "/Solver", component: SolverPage },
  { path: "/AccountSettings/ImportClassLists", component: ImportSolutionPage },
  { path: "/Solver/MapColumns", component: ImportSolutionMapColumns },

  { path: "/Teachers", locked: false, component: TeachersPage },
  {
    path: "/Teachers/MapColumns",
    locked: false,
    component: TeachersMapColumns,
  },
  {
    path: "/Teachers/MapClassLabels",
    locked: false,
    component: TeachersMapClassLabels,
  },
  {
    path: "/Teachers/Upload",
    locked: false,
    component: TeachersUploadStart,
  },
  { path: "/Students", locked: false, component: StudentsPage },
  {
    path: "/Students/Characteristics/MapColumns",
    locked: false,
    component: CharacteristicsMapColumns,
  },
  {
    path: "/Students/Characteristics/MapResponseLabels",
    locked: false,
    component: CharacteristicsMapResponseLabels,
  },
  {
    path: "/Students/Characteristics/Upload",
    locked: false,
    component: CharacteristicsUploadStart,
  },
  {
    path: "/Students/FriendshipPreferences/Upload",
    locked: false,
    component: FriendshipPreferencesUpload,
  },
  {
    path: "/Students/FriendshipPreferences/MapStudents",
    locked: false,
    component: FriendshipPreferencesMapStudents,
  },
  {
    path: "/Students/StudentNotes/MapColumns",
    locked: false,
    component: StudentNotesMapColumns,
  },
  {
    path: "/Students/StudentNotes/Upload",
    locked: false,
    component: StudentNotesUpload,
  },
  {
    path: "/Students/Upload/Columns",
    locked: false,
    component: ColumnMappingPage,
  },
  {
    path: "/Students/Upload/GradeLabels",
    locked: false,
    component: GradeLabelsPage,
  },
  {
    path: "/Students/Upload/Grades",
    locked: false,
    component: GradeSystemPage,
  },
  {
    path: "/Students/Upload/IdChangeWarning",
    locked: false,
    component: IdChangeWarningPage,
  },
  {
    path: "/Students/Upload/Success",
    locked: false,
    component: ImportSuccessPage,
  },
  { path: "/SummaryReport/Class", component: ReportClassPage },
  { path: "/SummaryReport/School", component: ReportSchoolPage },
  { path: "/SummaryReport/Sociogram", component: ReportSociogramPage },
  { path: "/SuperAdmin", locked: false, component: SuperAdminPage },
].map(route => {
  const Component = route.component
  return {
    path: route.path,
    element: (
      <CheckPrivateAuth locked={route.locked}>
        <div className="u-layout-page">
          <Nav />
          <Component />
        </div>
      </CheckPrivateAuth>
    ),
    errorElement: <ErrorBoundary />,
  }
})

const router = createBrowserRouter([
  ...publicRoutes,
  ...privateRoutes,
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
])

export const Router = () => {
  return <RouterProvider router={router} />
}
