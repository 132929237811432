import React from "react"
import { ModalBody } from "reactstrap"

import {
  Loader,
  ClassBadge,
  ModalHeader,
  DeleteModal,
  ButtonBar,
  InlineError,
} from "components"
import { StudentInputs } from "domains/students/components"
import * as resolverTypes from "constants/resolverTypes"
import { STUDENT_CODE } from "domains/students/errorFields"
import { handleSessionExpired } from "util/app"
import * as validators from "util/validators"
import { getPropertyIfDefined } from "util/objUtil"
import { SCHOOL_ID } from "constants/storageTokens"
import { genderColor, genderName } from "util/studentUtil"
import { defaultNewGradeForStudent } from "util/gradeUtil"
import { AccountTypeContext } from "config/accountTypeContext"

export class StudentDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
      deleteModal: false,
      deleteLoading: false,
      // Get student initial state from props
      student: {
        schoolId: sessionStorage.getItem(SCHOOL_ID),
        id: this.props.student.id,
        studentCode: this.props.student.studentCode,
        firstName: this.props.student.firstName,
        lastName: this.props.student.lastName,
        currentClass:
          getPropertyIfDefined(this.props, "student.currentClass.label") || "",
        currentGrade: getPropertyIfDefined(
          this.props,
          "student.currentGrade.code"
        ),
        newGrade: getPropertyIfDefined(this.props, "student.newGrade.code"),
        newClass: getPropertyIfDefined(this.props, "student.newClass.label"),
        gender: this.props.student.gender,
        comments: this.props.student.comments,
      },
      errors: {},
    }
  }

  static contextType = AccountTypeContext

  onChange = e => {
    const { student } = this.state
    if (e.target.name === "currentGrade") {
      const { currentSchoolGrades } = this.props
      // get the selected grade
      const currentGrade = currentSchoolGrades.find(
        grade => grade.code === e.target.value
      )
      const newGrade = defaultNewGradeForStudent(currentGrade, student)

      this.setState({
        student: {
          ...student,
          // clear the currentClass
          currentClass: "",
          newGrade: newGrade ? newGrade.code : "",
          // clear new class
          newClass: "",
          [e.target.name]: e.target.value,
        },
      })
    } else {
      // Update Student
      this.setState({
        student: {
          ...student,
          // clear the newClass if newGrade is changed
          newClass: e.target.name === "newGrade" ? "" : student.newClass,
          [e.target.name]: e.target.value,
        },
      })
    }
  }

  onCurrentClassChange = e => {
    const { student } = this.state
    if (e === null || e.label === "N/A") {
      this.setState({
        student: {
          ...student,
          currentClass: "",
        },
      })
    } else {
      this.setState({
        student: {
          ...student,
          currentClass: e.label,
        },
      })
    }
  }

  onGenderChange = e => {
    this.setState({
      student: {
        ...this.state.student,
        gender: e.target.value,
      },
    })
  }

  toggleEdit = () => {
    this.props.toggleEdit()
    this.setState({
      edit: !this.state.edit,
    })
  }

  onCancelEdit = () => {
    const { student } = this.props
    const { firstName, lastName, id, studentCode, gender, comments } = student

    // Toggle edit mode and revert changes
    this.toggleEdit()
    this.setState({
      student: {
        schoolId: sessionStorage.getItem(SCHOOL_ID),
        id,
        studentCode,
        firstName,
        lastName,
        currentClass: getPropertyIfDefined(student, "currentClass.label"),
        currentGrade: getPropertyIfDefined(student, "currentGrade.code"),
        newGrade: getPropertyIfDefined(student, "newGrade.code"),
        newClass: getPropertyIfDefined(student, "newClass.label"),
        comments,
        gender,
      },
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    })
  }

  saveStudentClick = () => {
    const {
      createOrUpdateStudentMutation,
      refetchQueries,
      updateStudentEditFlag,
    } = this.props
    const studentFromProps = this.props.student
    const { student } = this.state
    // Check that all fields are valid
    const errors = validators.validateStudent(student)
    this.setState({ errors })

    // Do not update some fields which haven't changed, this makes sure we don't overwrite any
    // truncated fields
    const studentParams = Object.fromEntries(
      Object.entries(student).filter(([key, value]) => {
        if (
          this.props.student &&
          ["firstName", "lastName", "comments", "studentCode"].includes(key)
        ) {
          return this.props.student[key] !== value
        }

        return true
      })
    )

    // Do Mutation If fields are valid
    const variables = {
      student: {
        ...studentParams,
        newClass: student.newClass === "" ? "" : student.newClass,
      },
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      this.setState({
        student: {
          ...student,
          studentCode: studentFromProps.studentCode,
        },
      })
      createOrUpdateStudentMutation({
        variables,
        refetchQueries,
      })
        .then(() => {
          // Close edit view
          this.toggleEdit()
          this.setState({
            loading: false,
          })

          // check if the students new grade has been updated
          if (
            student.newGrade !==
            getPropertyIfDefined(this.props, "student.newGrade.code")
          ) {
            updateStudentEditFlag({
              variables: {
                type: resolverTypes.STUDENT_NEW_GRADE_CHANGE,
                value: true,
              },
            })
          } else {
            // flag that the student has been updated
            updateStudentEditFlag({
              variables: { type: resolverTypes.STUDENT_UPDATED, value: true },
            })
          }
        })
        .catch(error => {
          handleSessionExpired(error)
          if (error.toString().includes(STUDENT_CODE.key)) {
            // If the conflicting student is inactive, re-activate it
            // so they can manually resolve the conflict.
            const variables = {
              student: {
                active: true,
                schoolId: student.schoolId,
                studentCode: student.studentCode,
              },
            }

            createOrUpdateStudentMutation({ variables, refetchQueries })

            this.setState({
              loading: false,
              errors: { studentId: STUDENT_CODE.message },
            })
          } else {
            this.setState({
              loading: false,
              errors: { mutation: "Network Error, Could Not Save Student" },
            })
          }
        })
    }
  }

  // TO DO: Fix when delete API is available
  onDeleteClick = () => {
    const {
      toggle,
      deleteStudentMutation,
      updateSelectedStudent,
      updateStudentEditFlag,
      deleteRefetchQueries,
    } = this.props
    const {
      student: { id },
    } = this.state

    this.setState({ deleteLoading: true, errors: {} })

    deleteStudentMutation({
      variables: { id },
      refetchQueries: deleteRefetchQueries,
    })
      .then(() => {
        this.setState({ deleteLoading: false, deleteModal: false })
        // flag that the student has been updated
        updateStudentEditFlag({
          variables: { type: resolverTypes.DELETED_STUDENT, value: id },
        })
        // De-Select the student on solver page
        updateSelectedStudent({
          variables: {
            id: "",
            firstName: "",
            lastName: "",
            friends: [],
            teacherConstraints: [],
            studentConstraints: [],
          },
        }).then(() => {
          toggle()
        })
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          deleteLoading: false,
          errors: {
            delete: "Network Error, Could not delete Student",
          },
        })
      })
  }

  render() {
    const {
      toggle,
      newSchoolGrades,
      currentSchoolGrades,
      currentClasses,
      newClasses,
      editable,
      isTeacher,
      student: {
        firstName,
        lastName,
        gender,
        studentCode,
        currentClass,
        comments,
      },
    } = this.props
    const { loading, edit, student, errors, deleteLoading } = this.state

    // If edit view is open
    if (edit) {
      return (
        <div>
          <ModalHeader
            title="Edit Student"
            toggle={toggle}
            className="px-2rem py-3"
          />

          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <ModalBody className="px-2rem">
              {/* Input Fields */}
              <StudentInputs
                isTeacher={isTeacher}
                student={student}
                onChange={this.onChange}
                onGenderChange={this.onGenderChange}
                onCurrentClassChange={this.onCurrentClassChange}
                errors={this.state.errors}
                currentSchoolGrades={currentSchoolGrades}
                newSchoolGrades={newSchoolGrades}
                currentClasses={currentClasses}
                newClasses={newClasses}
              />

              <ButtonBar
                onCancelClick={this.onCancelEdit}
                buttonText="Save Student"
                onButtonClick={this.saveStudentClick}
              />
              <InlineError text={errors.mutation} />
            </ModalBody>
          )}
        </div>
      )
    }

    const gettextObj = this.context.gettextObj

    return (
      <div className="c-student-modal-details px-2rem">
        <div className="mb-4">
          <div className="container-fluid mt-4 mb-3">
            <div className="row">
              <h1 className="mr-a">{firstName + " " + lastName}</h1>
              <i
                className="u-modal__close-icon fa fa-close cursor-pointer"
                onClick={toggle}
              />
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between">
            <span className="c-student-modal-details__student-id">
              STUDENT ID - {studentCode}
            </span>
            {editable && (
              <div>
                <span
                  className="text-primary font-size-14 mr-4 cursor-pointer"
                  onClick={this.toggleEdit}>
                  Edit
                </span>
                {!isTeacher && (
                  <span
                    className="text-primary font-size-14 cursor-pointer"
                    onClick={this.toggleDeleteModal}>
                    Delete Student
                  </span>
                )}
              </div>
            )}
          </div>
          <div className={`w-100 mt-2 ${genderColor(gender)}`}>
            {genderName(gender)}
          </div>
          <div className="w-100 d-flex justify-content-start align-items-center my-2">
            <span className="pr-3">
              Current Grade:{" "}
              {getPropertyIfDefined(this.props, "student.currentGrade.label")}
            </span>
            <ClassBadge label={currentClass ? currentClass.label : ""} />
            <span className="pl-5">
              {gettextObj.gettext("New Grade")}:{" "}
              {getPropertyIfDefined(this.props, "student.newGrade.label")}
            </span>
          </div>
          <div className="">Placement Notes: {comments}</div>
        </div>
        <DeleteModal
          isOpen={this.state.deleteModal}
          toggle={this.toggleDeleteModal}
          loading={deleteLoading}
          heading="Delete Student?"
          text="This will delete ALL
          information associated with this student, such as Requests,
          Characteristics and Friendship Preferences."
          onButtonClick={this.onDeleteClick}
          error={errors.delete}
        />
      </div>
    )
  }
}
