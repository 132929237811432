// Default State for Apollo Link State
import { gql } from "@apollo/client"

export const defaultQuery = gql`
  query {
    email @client {
      __typename
      email
    }
    solver @client {
      __typename
      activeGrade
      undoMoves
      redoMoves
      lastAssignmentCached
      selectedTeacherId
      selectedStudent {
        __typename
        studentId
        firstName
        lastName
        friends
        teacherConstraints
        studentConstraints
      }
    }
    studentFlags @client {
      __typename
      studentNewGradeChange
      studentUpdated
      deletedStudent
    }
    settings @client {
      __typename
      adminOnlyRequests
    }
    studentsImport @client {
      __typename
      jobId
      grades
      columns
    }
  }
`

export const DefaultState = {
  email: {
    __typename: "email",
    email: "",
  },
  solver: {
    __typename: "solver",
    activeGrade: "",
    undoMoves: [],
    redoMoves: [],
    lastAssignmentCached: "",
    selectedTeacherId: "",
    selectedStudent: {
      __typename: "selectedStudent",
      studentId: "",
      firstName: "",
      lastName: "",
      friends: [],
      teacherConstraints: [],
      studentConstraints: [],
    },
  },
  studentFlags: {
    __typename: "studentFlags",
    studentNewGradeChange: false,
    studentUpdated: false,
    deletedStudent: "",
  },
  settings: {
    __typename: "settings",
    adminOnlyRequests: false,
  },
  studentsImport: {
    __typename: "studentsImport",
    jobId: null,
    grades: [],
    columns: [],
  },
}
